import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import Card from '../components/card'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import slugify from '../utils/slugify'
import Footer from '../components/footer'

const YachtsPage = ({ data: { datoCmsYachtsPage: { header, description, heroImage, yachts } } }) => {
  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={header}
          text={description}
        />
        {yachts.map(({ name, media, description, originalId }) => (
          <Card
            key={originalId}
            title={name}
            text={description}
            media={Array.isArray(media) ? media[0] : media}
            to={`/yachts/${ slugify(name) }`}
          />
        ))}
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query YachtsPage {
    datoCmsYachtsPage {
      header
      description
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      yachts {
        name
        description
        details
        originalId
        media {
          id
          alt
          fluid(maxWidth: 1000, maxHeight: 650, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default YachtsPage
